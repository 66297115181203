import React, { useEffect, useRef, useState } from "react"
import { faBolt, faSpinner, faTrophy } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import Winwheel from "./wheel"
import useTranslations from "components/use-translations"

export default function WinWheel({ items = [], onStarted, onStopped }) {
  const {
    cekilis: { cekilisiBaslat, cekilisYapiliyor, kazanan },
  } = useTranslations()
  const [running, setRunning] = useState(false)
  const [winner, setWinner] = useState("")
  let theWheel
  const audioRef = useRef({ current: null })

  useEffect(() => {
    if (audioIsAvalible) {
      audioRef.current.src = "/sounds/wheel.mp3"
    }
  }, [])

  const audioIsAvalible = audioRef.current && audioRef.current.volume

  useEffect(() => {
    if (theWheel) theWheel.stopAnimation()

    if (audioIsAvalible) {
      audioRef.current.src = "/sounds/wheel.mp3"
      audioRef.current.pause()
      audioRef.current.currentTime = 0
    }
    setWinner("")
    setRunning(false)
    generateWheel()
  }, [items])

  const startWheel = () => {
    if (!running) {
      if (!generateWheel()) return
      onStarted()
      setWinner("")

      audioRef.current.currentTime = 0
      audioRef.current.play()

      theWheel.startAnimation()
      setRunning(true)
    }
  }

  const drawTriangle = () => {
    const op = {
      taban: 50,
      alt: 10,
      ctx: theWheel.ctx,
      sf: theWheel.scaleFactor,
      x: theWheel.centerX - 50 / 2,
      y: theWheel.scaleFactor * 5,
    }
    op.ctx.strokeStyle = "#eab9b9" // Set line colour.
    op.ctx.fillStyle = "#870202" // Set fill colour.
    op.ctx.lineWidth = 2

    op.ctx.beginPath() // Begin path.
    op.ctx.moveTo(op.x * op.sf, op.y) // Move to initial position.
    op.ctx.lineTo((op.x + op.taban) * op.sf, op.y) // Draw lines to make the shape.
    op.ctx.lineTo((op.x + op.taban / 2) * op.sf, op.y * op.alt)
    op.ctx.lineTo((op.x + 1) * op.sf, op.y)
    op.ctx.stroke() // Complete the path by stroking (draw lines).
    op.ctx.fill() // Then fill.
  }

  const generateWheel = () => {
    document.getElementById("canvas-container").style.display = "block"

    const colors = ["#7a42ff", "#ffaa00", "#ff4747", "#2d69ff", "#258c44"]
    let segments = items.map((element, index) => {
      return {
        fillStyle: colors[index % colors.length],
        text: element,
      }
    })

    var canvasContainerWidth = document.getElementById("canvas-container")
      .offsetWidth

    document.getElementById("canvas").width = canvasContainerWidth

    theWheel = new Winwheel({
      numSegments: segments.length, // Number of segments
      outerRadius: canvasContainerWidth > 500 ? 212 : 170, // The size of the wheel.
      textFontSize: 18, // Font size.
      segments: segments,
      responsive: true,
      animation: {
        type: "spinToStop",
        duration: 10,
        spins: 6,
        callbackFinished: () => alertPrize(),
        callbackAfter: () => drawTriangle(),
      },
    })
    // Called when the animation has finished.
    const alertPrize = () => {
      // Get the number of the winning segment.
      const winningSegmentNumber = theWheel.getIndicatedSegmentNumber()

      theWheel.segments.forEach((segment, index) => {
        if (index === 0) return

        segment.fillStyle = index === winningSegmentNumber ? "yellow" : "gray"
      })

      // Call draw function to render changes.
      theWheel.draw()
      drawTriangle()
      // Also re-draw the pointer, otherwise it disappears.

      setRunning(false)
      if (
        theWheel &&
        theWheel.segments &&
        theWheel.segments[winningSegmentNumber]
      ) {
        setWinner(theWheel.segments[winningSegmentNumber].text)
        onStopped(theWheel.segments[winningSegmentNumber].text)
      }
    }

    return true
  }

  const getButtonText = () => {
    if (running)
      return (
        <>
          <FontAwesomeIcon size="lg" pulse={true} icon={faSpinner} />
          <span className="ml-2">{cekilisYapiliyor}...</span>
        </>
      )
    else if (winner)
      return (
        <>
          <FontAwesomeIcon size="lg" icon={faTrophy} />
          <span className="ml-2">
            {kazanan} {winner}
          </span>
        </>
      )
    else {
      return (
        <>
          <FontAwesomeIcon size="lg" icon={faBolt} />
          <span className="ml-2">{cekilisiBaslat}...</span>
        </>
      )
    }
  }

  return (
    <>
      <audio ref={audioRef} />
      <div id="canvas-container" className=" d-block mt-5">
        <button
          onClick={() => startWheel()}
          className={`btn  w-100 btn-${
            winner ? "success" : running ? "warning" : "info"
          }`}
        >
          {getButtonText()}
        </button>
        <canvas
          id="canvas"
          className="center-block mt-4"
          style={{ cursor: "pointer" }}
          onClick={() => startWheel()}
          width="880"
          height="500"
        >
          Canvas not supported, use newer browser.
        </canvas>
      </div>
    </>
  )
}

WinWheel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  onStarted: PropTypes.func,
  onStopped: PropTypes.func,
}
