import React, { useState } from "react"

import LayoutCekilis from "components/layout-cekilis"
import { RaffleTypes } from "components/layout-cekilis"
import SEO from "components/seo"
import ShareButton from "components/share-button"
import WinWheel from "components/win-wheel"
import useTranslations from "components/use-translations"

export default function CarkCekilis() {
  const [startedAsEmpty, setStartedAsEmpty] = useState(false)
  const [participants, setParticipants] = useState([])
  const [winner, setWinner] = useState("")

  const onStarted = () => setWinner("")
  const onStopped = newWinner => setWinner(newWinner)

  const {
    cekilis: {
      katilimciListesiPlaceHolder,
      carkSeoTitle,
      carkSeoDesc,
      carkCekilisYap,
    },
  } = useTranslations()
  return (
    <>
      <LayoutCekilis headerText={carkCekilisYap} type={RaffleTypes.Cark}>
        <SEO
          title={carkSeoTitle}
          description={carkSeoDesc}
          pathname="https://www.kodvizit.com/cekilis/cark"
          datePublished="2017-02-08T22:39:04+00:00"
          dateModified="2020-08-05T16:53:13+00:00"
        />
        <textarea
          placeholder={katilimciListesiPlaceHolder}
          className={`form-control mb-4 text-secondary ${
            startedAsEmpty && "is-invalid"
          } `}
          id="katilimcilar"
          name="katilimcilar"
          rows="8"
          onChange={e => {
            if (startedAsEmpty) setStartedAsEmpty(false)
            setParticipants(e.target.value.split("\n"))
          }}
          value={participants.join("\n")}
          spellCheck="false"
        />

        <WinWheel
          items={participants}
          onStarted={onStarted}
          onStopped={onStopped}
        />

        <div className="d-flex align-items-center my-5">
          {winner && (
            <div className="mx-auto" style={{ maxWidth: 400 }}>
              <ShareButton
                type={RaffleTypes.Cark}
                participants={participants}
                winners={[winner]}
              />
            </div>
          )}
        </div>
      </LayoutCekilis>
    </>
  )
}
